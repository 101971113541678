import { gql } from "@apollo/client";
import GraphQlService from "./graphql";

class RolesGraphql extends GraphQlService {
 
    async getAllCustomRolesByCurrentUser() {
        return this.client
          .query({
            query: gql`
                  query {
                    getAllCustomRolesByCurrentUser{
                      id
                      nameSW
                      nameENG
                      code
                      masterRoles{
                        code
                      }
                    }
                  }
                `
            })
        .then((result) => result?.data?.getAllCustomRolesByCurrentUser);
    }

    async getAllCustomRolesByAccountId(accountId) {
      return this.client
        .query({
          query: gql`
                query {
                  getAllCustomRolesByAccountId(accountId: "${accountId}"){
                    id
                    nameSW
                    nameENG
                    code
                    masterRoles{
                      code
                    }
                  }
                }
              `
          })
      .then((result) => result?.data?.getAllCustomRolesByAccountId);
    }

    async getRoleFeaturesByCurrentUser() {
      return this.client
        .query({
          query: gql`
                query {
                  findRoleFeaturesByCurrentUser {
                    id
                    customRoles {
                      masterRoles {
                        code
                      }
                    }
                    roleFeatures {
                      code
                    }
                  }
                }
              `
          })
      .then((result) => result?.data?.findRoleFeaturesByCurrentUser);
  }

}

const RolesGraphqlInstance = new RolesGraphql();
export default RolesGraphqlInstance;
